<template>
  <SectionBlocks
    :loading="!paymentMethods.length && store.loading"
    :collection="paymentMethods"
    data-testid="payment-methods"
  >
    <template #title>
      <h3>
        Payment Methods
      </h3>
      <NuxtLink
        v-if="canAddBraintree"
        to="/account/payment-methods/new"
        class="button +small +green"
        data-gtm-action="add payment method"
      >
        Add Payment Method
      </NuxtLink>
    </template>
    <template #empty>
      You don't have any payment methods
    </template>
    <template #record="{ record }">
      <PaymentMethodListItem :pm="record" />
    </template>
    <template #loading>
      <SkeletonPaymentMethods />
    </template>
  </SectionBlocks>
</template>

<script setup lang="ts">
import { usePaymentMethodStore } from '@/stores/recharge'

const store = usePaymentMethodStore()
const paymentMethods = store.usePaymentMethods()
const canAddBraintree = computed(() =>
  store.loading === false && !store.paymentMethods.some(i => i.processor_name === 'shopify_payments')
)
</script>
