<template>
  <div class="flex flex-col gap-1">
    <div class="font-semibold">
      Shipping Addresses
    </div>
    <div
      v-if="addresses?.length"
      class="flex flex-col gap-3"
    >
      <div
        v-for="address in addresses"
        :key="address.id"
        class="flex gap-2 pb-3 border-b"
      >
        <SvgIcon
          name="location"
          class="mt-1 h-4 w-4 text-purple-400"
        />
        <AddressDetail :address="address" />
      </div>
    </div>
    <p
      v-else
      class="py-1"
    >
      No associated shipping addresses.
    </p>
  </div>
</template>

<script setup lang="ts">
import type { PaymentMethod } from '@rechargeapps/storefront-client'

export interface Props {
  pm: PaymentMethod
}
const props = defineProps<Props>()
const addresses = computed(() => props.pm.include?.addresses)
</script>
